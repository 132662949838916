import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table_column = _resolveComponent("base-table-column")!
  const _component_base_table_row = _resolveComponent("base-table-row")!
  const _component_base_toggle_switch = _resolveComponent("base-toggle-switch")!
  const _component_base_table = _resolveComponent("base-table")!

  return (_openBlock(), _createBlock(_component_base_table, {
    colspan: String(_ctx.menus.length)
  }, {
    header: _withCtx(() => [
      _createVNode(_component_base_table_row, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu, i) => {
            return (_openBlock(), _createBlock(_component_base_table_column, {
              type: "label",
              "text-align": "center",
              key: i
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans(`menu.${menu.title}`)), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_base_table_row, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuAccesses, (menuAccess, j) => {
            return (_openBlock(), _createBlock(_component_base_table_column, {
              key: j,
              textAlign: "center",
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_base_toggle_switch, {
                  modelValue: menuAccess.permission,
                  "onUpdate:modelValue": ($event: any) => ((menuAccess.permission) = $event),
                  onChange: _withModifiers(($event: any) => (_ctx.setAccess(menuAccess)), ["stop"])
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["colspan"]))
}