import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table_column = _resolveComponent("base-table-column")!
  const _component_base_table_row = _resolveComponent("base-table-row")!
  const _component_base_table = _resolveComponent("base-table")!

  return (_openBlock(), _createBlock(_component_base_table, {
    colspan: "3",
    loading: _ctx.loading,
    hasMore: _ctx.hasMore,
    onLoadMore: _ctx.loadMore,
    noDataAvailable: _ctx.noDataAvailable,
    noResultFound: _ctx.noResultFound,
    hover: ""
  }, {
    header: _withCtx(() => [
      _createVNode(_component_base_table_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_base_table_column, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.fleet.ship_name")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_base_table_column, {
            type: "label",
            "text-align": "center"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.fleet.crew_on_board")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
        return (_openBlock(), _createBlock(_component_base_table_row, {
          key: row.id,
          onClick: _withModifiers(($event: any) => (
        _ctx.useRouter().push({
          name: _ctx.RouteNames.FleetDetail,
          params: {
            id: row.id
          }
        })
      ), ["stop"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_base_table_column, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.ship_name), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_base_table_column, { "text-align": "center" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.crew_boards_count), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["loading", "hasMore", "onLoadMore", "noDataAvailable", "noResultFound"]))
}