
import { helperMixin, infinityScrollTable } from "@/mixins";
import { defineComponent } from "vue";
import fleetHttp from "@/http/fleet";
import { FleetPaginate, FleetPaginateFilter } from "@/models/fleet";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";

export default defineComponent({
  components: {
    BaseTable,
    BaseTableRow,
    BaseTableColumn
  },
  props: {
    companyId: {
      required: true
    }
  },
  mixins: [
    helperMixin,
    infinityScrollTable<FleetPaginate, FleetPaginateFilter>(fleetHttp())
  ],
  mounted() {
    this.filter.company_id = this.companyId;
  }
});
