import { createInstance } from '@/lib/fn'
import BaseResourceHttp, { Excepts } from '@/lib/resourceHttp'
import { Menu, MenuAccessSave } from '@/models/menuAccess'

class MenuAccessHttp extends BaseResourceHttp<any, MenuAccessSave, MenuAccessSave> {
    protected ignoreMethods: Excepts[] = ['paginate', 'delete', 'create'];

    protected baseURL(): string {
        return "menu_access";
    }

    async getMenus(): Promise<Menu[]> {
        const { response } = await this.request(
            "GET",
            this.url('menus')
        );

        return response.data ?? [];
    }
}

export default createInstance<MenuAccessHttp>(MenuAccessHttp);