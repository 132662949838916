
import { helperMixin } from "@/mixins";
import { defineComponent, PropType } from "vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseToggleSwitch from "@/components/Base/BaseToggleSwitch.vue";
import { Menu, MenuAccess } from "@/models/MenuAccess";
import menuAccessHttp from "@/http/menuAccess";

export default defineComponent({
  components: {
    BaseTable,
    BaseTableRow,
    BaseTableColumn,
    BaseToggleSwitch
  },
  mixins: [helperMixin],
  props: {
    menuAccesses: {
      type: Array as PropType<Array<any>>,
      required: true
    }
  },
  computed: {
    menuAccessHttp
  },
  data() {
    return {
      menus: [] as Menu[]
    };
  },
  async mounted() {
    this.menus = await this.menuAccessHttp.getMenus();
  },
  methods: {
    async setAccess(menuAccess: MenuAccess) {
      if (
        await this.requestHandler(
          this.menuAccessHttp.update(
            {
              company_id: menuAccess.company_id,
              menu_id: menuAccess.menu_id,
              permission: menuAccess.permission
            },
            menuAccess.id
          )
        )
      ) {
        return;
      }

      menuAccess.permission = menuAccess.permission ? 0 : 1;
    },

    async mounted() {
      this.menus = await this.menuAccessHttp.getMenus();
    }
  }
});
