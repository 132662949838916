
import {
  infinityScrollTable,
  helperMixin,
  filterHelper,
  positionFilter
} from "@/mixins";
import { JobFilter, JobPaginate } from "@/models/job";
import { defineComponent } from "vue";
import jobHttp from "@/http/job";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";

export default defineComponent({
  components: {
    BaseTable,
    BaseTableRow,
    BaseTableColumn
  },

  mixins: [
    filterHelper<JobFilter>(),
    positionFilter<JobFilter>(),
    helperMixin,
    infinityScrollTable<JobPaginate, JobFilter>(jobHttp())
  ],

  props: {
    companyId: {
      required: true
    }
  },

  mounted() {
    this.filter.company_id = this.companyId;
  }
});
