import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table_column = _resolveComponent("base-table-column")!
  const _component_base_table_row = _resolveComponent("base-table-row")!
  const _component_avatar_img = _resolveComponent("avatar-img")!
  const _component_base_table = _resolveComponent("base-table")!

  return (_openBlock(), _createBlock(_component_base_table, {
    colspan: "6",
    onLoadMore: _ctx.loadMore,
    loading: _ctx.loading,
    hasMore: _ctx.hasMore,
    noDataAvailable: _ctx.noDataAvailable,
    noResultFound: _ctx.noResultFound,
    hover: ""
  }, {
    header: _withCtx(() => [
      _createVNode(_component_base_table_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_base_table_column, { type: "label" }),
          _createVNode(_component_base_table_column, {
            type: "label",
            class: "pl-0"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.user.fullname")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_base_table_column, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.user.email")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_base_table_column, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.job.position_id")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_base_table_column, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.crew.join_date")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
        return (_openBlock(), _createBlock(_component_base_table_row, {
          key: row.id,
          onClick: _withModifiers(($event: any) => (
        _ctx.useRouter().push({
          name: _ctx.RouteNames.SailorDetail,
          params: {
            id: row.id
          }
        })
      ), ["stop"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_base_table_column, { class: "w-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_avatar_img, {
                  src: row.avatar_link,
                  alt: `${row.fullname}_avatar`
                }, null, 8, ["src", "alt"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_base_table_column, { class: "pl-0" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.fullname), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_base_table_column, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.email), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_base_table_column, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.position), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_base_table_column, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(row.join_date)), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["onLoadMore", "loading", "hasMore", "noDataAvailable", "noResultFound"]))
}