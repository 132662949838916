import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-092d3936"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    class: "appearance-none rounded-full align-top focus:outline-none cursor-pointer",
    type: "checkbox",
    checked: _ctx.inputValue,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.inputValue = $event.target.checked))
  }, null, 40, _hoisted_1))
}