
import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";
import companyHttp from "@/http/company";
import { CompanyDetail } from "@/models/company";
import { toast } from "@/template/app";
import AvatarImg from "@/components/AvatarImg.vue";
import CompanyDetailJob from "@/components/CompanyDetail/CompanyDetailJob.vue";
import CompanyDetailPermission from "@/components/CompanyDetail/CompanyDetailPermission.vue";
import CompanyDetailCrew from "@/components/CompanyDetail/CompanyDetailCrew.vue";
import CompanyDetailFleet from "@/components/CompanyDetail/CompanyDetailFleet.vue";

export default defineComponent({
  components: {
    AvatarImg,
    CompanyDetailJob,
    CompanyDetailPermission,
    CompanyDetailCrew,
    CompanyDetailFleet
  },
  mixins: [helperMixin],
  data: () => {
    return {
      company: {} as CompanyDetail
    };
  },
  computed: {
    companyHttp
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const { status, message, response } = await this.companyHttp.read(
        this.currentRoute.params?.id
      );

      if (status === 200) {
        this.company = response.data;
      } else if (status === 404) {
        this.useRouter().push({
          name: this.currentRoute.meta?.rootName
        });
      } else {
        toast("error", message, 0);
      }
    }
  }
});
